<template>
  <BaseModal
    name="links-new"
    size="lg"
    :title="all_edit ? $t('seller.links.new.text_299') : $t('seller.links.new.text_281')"
    @shown="openModal"
    @hidden="resetItens"
  >
    <!-- Create new link form -->
    <b-row v-if="all_edit === null">
      <b-col cols="12">
        <h5 class="produto-nome">• {{ product_name }}</h5>
        <div v-if="!loading" class="checks">
          <b-form-checkbox
            v-if="showOrderBump"
            v-model="order_b"
            name="check-button"
            switch
            @change="changeCheckBox($event, 'orderbump')"
          >
            <p class="title-checkbox">Order Bump</p>
          </b-form-checkbox>
          <b-form-checkbox
            v-if="(!$store.getters.setPanel || ($store.getters.setPanel && checkoutRead))"
            v-model="checkout"
            name="check-button"
            switch
            @change="changeCheckBox($event, 'checkout')"
          >
            <p class="title-checkbox">{{ $t("seller.links.new.text_2723") }}</p>
          </b-form-checkbox>
          <b-form-checkbox
            v-model="cupom"
            name="check-button"
            switch
            @change="changeCheckBox($event, 'cupom')"
          >
            <p class="title-checkbox">{{ $t("seller.links.new.text_2724") }}</p>
          </b-form-checkbox>
          <b-form-checkbox
            v-if="eventTickets"
            v-model="fixed_batchs"
            name="check-button"
            @change="changeCheckBox($event, 'fixed_batchs')"
            switch
          >
            <p class="title-checkbox">Lotes Fixos</p>
          </b-form-checkbox>
        </div>
        <!-- Loading -->
        <div class="d-flex justify-content-center" v-if="loading">
          <b-spinner label="Loading..."></b-spinner>
        </div>
        <b-form novalidate v-if="!loading">
          <!-- Link Title -->
          <b-form-group
            v-if="order_b || checkout || cupom || fixed_batchs"
            :label="$t('seller.links.new.text_282')"
            label-for="name"
          >
            <b-form-input
              id="name"
              name="name"
              v-model="name"
              type="text"
              placeholder="Meu Link"
              v-validate="'required'"
              :class="{ heaven: isHeaven }"
            ></b-form-input>
            <b-form-invalid-feedback :state="!errors.has('name')">
              {{ $t("seller.links.new.text_283") }}
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- OrderBump View -->
          <div v-if="order_b">
            <!-- Main Product -->
            <b-form-group
              :label="$t('seller.links.new.text_284')"
              label-for="product-main"
              data-anima="top"
            >
              <b-form-input
                id="product-main"
                name="product-main"
                v-model="product_name"
                type="text"
                disabled
                data-form="modificado"
                data-anima="top"
                :class="{ heaven: isHeaven }"
              ></b-form-input>
            </b-form-group>
            <!-- Offers -->
            <div v-if="offers && !eventTickets">
              <b-form-group :label="$t('seller.links.new.text_2725')">
                <multiselect
                  data-anima="top"
                  v-model="offer_select"
                  :placeholder="$t('seller.links.new.text_2726')"
                  label="name"
                  track-by="id"
                  :options="filterByOffer(offers)"
                  :multiple="false"
                  :taggable="false"
                  :class="{ heaven: isHeaven }"
                >
                  <template v-slot:noOptions>
                    <span>{{ $t("seller.links.new.text_2732") }}</span>
                  </template> 
                  <template v-slot:noResult>
                    <span>{{$t("seller.links.new.text_2732")}}</span>
                  </template> 
                  </multiselect>
              </b-form-group>
              <div class="mark-down mb-3">
                <p class="info-mark">
                  {{ $t("seller.links.new.text_2728") }}
                </p>
              </div>
            </div>
            <!-- OrderBump -->
            <section class="add-bump">
              <h5 class="label-title" data-anima="top">Order bump</h5>
              <button class="btn-add" type="button" @click="() => addNewBump()">
                <span
                  class="label-title"
                  v-b-tooltip="`Adicionar novo produto como order bump`"
                  >+</span
                >
              </button>
            </section>
            <section v-for="(item, index) in bumps" :key="item.id">
              <b-row>
                <!-- Selects dos produtos order bumps -->
                <b-col
                  :cols="bumps.length !== 1 ? 11 : 12"
                  class="d-flex flex-column"
                >
                  <b-form-group label="Produto Order Bump">
                    <multiselect
                      v-if="type"
                      data-anima="top"
                      v-model="item.bump"
                      :placeholder="$t('seller.links.new.text_286')"
                      label="name"
                      :name="`link-${item.id}`"
                      track-by="id"
                      :options="groupedProducts"
                      :multiple="false"
                      :taggable="false"                     
                      :class="{ heaven: isHeaven }"
                      @select="getBumpOffer"
                      @open="filterBy(affiliate_products, item.groupedProducts)"
                      >
                        <template v-slot:noResult>
                          <span>{{ $t("seller.links.new.text_2729")}}</span>
                        </template> 
                    </multiselect>                    
                    <multiselect
                      v-else
                      data-anima="top"
                      v-model="item.bump"
                      :placeholder="$t('seller.links.new.text_286')"
                      label="name"
                      :name="`link-${item.id}`"
                      track-by="id"
                      :options="groupedProducts"
                      :multiple="false"
                      :taggable="false"
                      :class="{ heaven: isHeaven }"
                      @select="getBumpOffer"
                      @open="filterBy(seller_products, item.bump)"
                      >
                      <template v-slot:option="{ option }">
                        <span v-if="option.tag === 'Afiliado' && option.product_type_id === 3 && eventFlagTicket" class="affiliate-option">
                          {{ option.name }} 
                          - <span class="option-disable">{{ $t('seller.links.new.text_301') }}</span>
                          - <span class="option-disable">{{ $t('seller.links.new.text_302') }}</span>
                        </span>
                        <span v-else-if="option.product_type_id === 3 && eventFlagTicket">
                          {{ option.name }} - <span class="option-disable">{{ $t('seller.links.new.text_302') }}</span>
                        </span>
                        <span v-else-if="option.tag === 'Afiliado'" class="affiliate-option">
                          {{ option.name }} - <span class="option-disable">{{ $t('seller.links.new.text_301') }}</span>
                        </span>
                        <span v-else>
                          {{ option.name }}
                        </span>
                        </template>
                        <template v-slot:noResult>
                          <span>{{ $t("seller.links.new.text_2729")}}</span>
                        </template> 
                    </multiselect>
                  </b-form-group>
                  <!-- Oferta do produto order bump -->
                  <template v-if="item.bump">
                    <b-form-group :label="$t('seller.links.new.text_2730')">
                      <multiselect
                        data-anima="top"
                        v-model="item.offer"
                        :placeholder="$t('seller.links.new.text_2731')"
                        label="name"
                        track-by="id"
                        :options="filterByOffer(item.bump.offers)"
                        :multiple="false"
                        :taggable="false"
                        :class="{ heaven: isHeaven }"
                      >
                        <template v-slot:noOptions>
                          <span>{{ $t("seller.links.new.text_2732") }}</span>
                        </template> 
                        <template v-slot:noResult>
                          <span>{{ $t("seller.links.new.text_2732") }}</span>
                        </template> 
                      </multiselect>
                    </b-form-group>
                    <div class="mark-down mb-3">
                      <p class="info-mark">
                        {{ $t("seller.links.new.text_2728") }}
                      </p>
                    </div>
                  </template>
                </b-col>
                <!-- Botão para excluir um order bump -->
                <b-col cols="1" v-if="bumps.length !== 1">
                  <button
                    type="button"
                    class="btn-remove"
                    @click="() => deleteBump(index)"
                  >
                    <span v-b-tooltip="`Remover order bump`">-</span>
                  </button>
                </b-col>
              </b-row>
            </section>
          </div>
          <!-- Custom Checkout View -->
          <div v-if="checkout && (!$store.getters.setPanel || ($store.getters.setPanel && linkCreate))">
            <!-- Main Product -->
            <b-form-group
              v-if="!order_b"
              :label="$t('seller.links.new.text_2734')"
              label-for="product-main"
              data-anima="top"
            >
              <b-form-input
                id="product-main"
                name="product-main"
                v-model="product_name"
                type="text"
                disabled
                data-form="modificado"
                data-anima="top"
                :class="{ heaven: isHeaven }"
              ></b-form-input>
            </b-form-group>
            <!-- Offers -->
            <div v-if="offers && !order_b && !eventTickets">
              <b-form-group :label="$t('seller.links.new.text_2725')">
                <multiselect
                  data-anima="top"
                  v-model="offer_select"
                  :placeholder="$t('seller.links.new.text_2726')"
                  label="name"
                  track-by="id"
                  :options="offers"
                  :multiple="false"
                  :taggable="false"
                  :class="{ heaven: isHeaven }"
                >
                  <template v-slot:noOptions>
                    <span>{{ $t("seller.links.new.text_2732")}}</span>
                  </template> 
                  <template v-slot:noResult>
                    <span>{{ $t("seller.links.new.text_2732") }}</span>
                  </template> 
                  </multiselect>
              </b-form-group>
              <div class="mark-down mb-3">
                <p class="info-mark">
                  {{ $t("seller.links.new.text_2728") }}
                </p>
              </div>
            </div>
            <!-- Custom Checkout -->
            <h5 class="label-title" data-anima="top">Checkout Personalizado</h5>
            <b-form-group :label="$t('seller.links.new.text_287')">
              <multiselect
                data-anima="top"
                v-model="checkout_select"
                :placeholder="$t('seller.links.new.text_2735')"
                label="name"
                :name="`link-${checkouts.id}`"
                track-by="id"
                :options="checkouts"
                :multiple="false"
                :custom-label="customLabel"
                :taggable="false"
                :class="{ heaven: isHeaven }"
              >
                <template v-slot:noResult>
                  <span>{{ $t("seller.links.new.text_2729") }}</span>
                </template>
              </multiselect>
            </b-form-group>
          </div>
          <!-- Cupom View -->
          <div v-if="cupom">
            <!-- Main Product -->
            <b-form-group
              v-if="!order_b"
              :label="$t('seller.links.new.text_2734')"
              label-for="product-main"
              data-anima="top"
            >
              <b-form-input
                id="product-main"
                name="product-main"
                v-model="product_name"
                type="text"
                disabled
                data-form="modificado"
                data-anima="top"
                :class="{ heaven: isHeaven }"
              ></b-form-input>
            </b-form-group>
            <!-- Offers -->
            <div v-if="offers && !order_b && !eventTickets">
              <b-form-group :label="$t('seller.links.new.text_2725')">
                <multiselect
                  data-anima="top"
                  v-model="offer_select"
                  :placeholder="$t('seller.links.new.text_2726')"
                  label="name"
                  track-by="id"
                  :options="offers"
                  :multiple="false"
                  :taggable="false"
                  :class="{ heaven: isHeaven }"
                >
                  <template v-slot:noOptions>
                    <span>{{ $t("seller.links.new.text_2732") }}</span>
                  </template>
                  <template v-slot:noResult>
                    <span>{{ $t("seller.links.new.text_2732") }}</span>
                  </template>
                  </multiselect>
              </b-form-group>
              <div class="mark-down mb-3">
                <p class="info-mark">
                  {{ $t("seller.links.new.text_2728") }}
                </p>
              </div>
            </div>
            <!-- Cupom -->
            <h5 class="label-title" data-anima="top">{{ $t("seller.links.new.text_2736") }}</h5>
            <b-form-group :label="$t('seller.links.new.text_288')">
              <multiselect
                data-anima="top"
                v-model="cupom_select"
                :placeholder="$t('seller.links.new.text_288')"
                label="name"
                track-by="id"
                :options="cupons"
                :multiple="false"
                :taggable="false"
                :class="{ heaven: isHeaven }"
              >
                <template v-slot:noOptions>
                  <span>{{ $t("seller.links.new.text_2737") }}</span>
                </template>
                <template v-slot:noResult>
                  <span>{{ $t("seller.links.new.text_2737") }}</span>
                </template>
                </multiselect>
            </b-form-group>
          </div>
          <!-- Fixed Batchs -->
          <div v-if="fixed_batchs">
            <h5 class="label-title" data-anima="top">Lotes fixos</h5>
            <b-form-group :label="'Selecione um ou mais Lotes'">
              <multiselect
                data-anima="top"
                v-model="batch_select"
                :placeholder="'Selecione um ou mais Lotes'"
                label="name"
                track-by="id"
                :options="offersBatches"
                :multiple="true"
                :taggable="false"
                :class="{ heaven: isHeaven }"
              >
                <template v-slot:noOptions>
                  <span>Nenhum lote encontrado</span>
                </template>
                <template v-slot:noResult>
                  <span>Nenhum lote encontrado</span>
                </template>
              </multiselect>
            </b-form-group>
            <div class="mark-down mb-3">
              <p class="info-mark">
                {{ $t("seller.links.new.text_2728") }}
              </p>
            </div>
          </div>
          <div v-if="(cupom || checkout || order_b) && loading === false">
            <b-row>
              <!-- Font -->
              <b-col cols="12" md="6">
                <b-form-group
                  :label="$t('seller.links.new.text_291')"
                  label-for="source"
                >
                  <b-form-input
                    id="url"
                    name="source"
                    v-model="source"
                    type="text"
                    placeholder="utm_source"
                    :class="{ heaven: isHeaven }"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('source')">
                    {{ $t("seller.links.new.text_292") }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <!-- Medium -->
              <b-col cols="12" md="6">
                <b-form-group
                  :label="$t('seller.links.new.text_293')"
                  label-for="medium"
                >
                  <b-form-input
                    id="medium"
                    name="medium"
                    v-model="medium"
                    type="text"
                    placeholder="facebook, email"
                    :class="{ heaven: isHeaven }"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('medium')">
                    {{ $t("seller.links.new.text_294") }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </div>

          <!-- Generated Link Create -->
          <div v-if="order_b || checkout || cupom || fixed_batchs">
            <div data-anima="top">
              <b-form-group :label="$t('seller.links.new.text_2738')">
                <b-form-input
                  ref="linkconfig"
                  name="url"
                  :value="paymentLink"
                  type="text"
                  disabled
                  :class="{ heaven: isHeaven }"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
          <!-- No Custom Checkbox Selected -->
          <div v-if="!order_b && !checkout && !cupom && !fixed_batchs">
            <div>
              <!-- Insert URL -->
              <b-form-group
                :label="$t('seller.links.new.text_289')"
                label-for="url"
              >
                <b-form-input
                  id="url"
                  name="url"
                  v-model="url"
                  type="text"
                  :placeholder="`https://${
                    isHeaven ? 'heaven' : 'greenn'
                  }.com.br/`"
                  v-validate="{ url: true, required: true }"
                  data-form="modificado"
                  :class="{ heaven: isHeaven }"
                ></b-form-input>
                <b-form-invalid-feedback :state="!errors.has('url')">
                  {{ $t("seller.links.new.text_290") }}
                </b-form-invalid-feedback>
              </b-form-group>
              <!-- Link Title -->
              <b-form-group
                :label="$t('seller.links.new.text_282')"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  name="name"
                  v-model="name"
                  type="text"
                  placeholder="Meu Link"
                  v-validate="'required'"
                  :class="{ heaven: isHeaven }"
                ></b-form-input>
                <b-form-invalid-feedback :state="!errors.has('name')">
                  {{ $t("seller.links.new.text_283") }}
                </b-form-invalid-feedback>
              </b-form-group>
              <b-row>
                <!-- Font -->
                <b-col cols="12" md="6">
                  <b-form-group
                    :label="$t('seller.links.new.text_291')"
                    label-for="source"
                  >
                    <b-form-input
                      id="url"
                      name="source"
                      v-model="source"
                      type="text"
                      placeholder="utm_source"
                      :class="{ heaven: isHeaven }"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="!errors.has('source')">
                      {{ $t("seller.links.new.text_292") }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <!-- Medium -->
                <b-col cols="12" md="6">
                  <b-form-group
                    :label="$t('seller.links.new.text_293')"
                    label-for="medium"
                  >
                    <b-form-input
                      id="medium"
                      name="medium"
                      v-model="medium"
                      type="text"
                      placeholder="facebook, email"
                      :class="{ heaven: isHeaven }"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="!errors.has('medium')">
                      {{ $t("seller.links.new.text_294") }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-form>
      </b-col>
    </b-row>
    <!-- Update link form -->
    <b-row v-else>
      <b-col cols="12">
        <h5 class="produto-nome">• {{ product_name }}</h5>

        <div class="d-flex justify-content-center" v-if="loading">
          <b-spinner label="Loading..."></b-spinner>
        </div>
        <b-form novalidate v-if="!loading">
          <!-- <b-form-group
            label="Título do Link"
            label-for="name"
            v-if="order_b || checkout || cupom || fixed_batchs"
          >
            <b-form-input
              id="name"
              name="name"
              v-model="name"
              type="text"
              :placeholder="$t('seller.links.new.text_2739')"
              v-validate="'required'"
              :class="{ heaven: isHeaven }"
            ></b-form-input>
            <b-form-invalid-feedback :state="!errors.has('name')">
              {{ $t("seller.links.new.text_283") }}
            </b-form-invalid-feedback>
          </b-form-group> -->
          <div v-if="all_edit.is_bump === 1">
            <h5 class="label-title" data-anima="top">Order Bump</h5>
            <b-form-group
              :label="$t('seller.links.new.text_2734')"
              label-for="url"
              data-anima="top"
            >
              <b-form-input
                id="url"
                name="url"
                v-model="product_name"
                type="text"
                disabled
                data-form="modificado"
                data-anima="top"
                :class="{ heaven: isHeaven }"
              ></b-form-input>
            </b-form-group>
            <div v-if="offers">
              <b-form-group :label="$t('seller.links.new.text_2725')">
                <multiselect
                  data-anima="top"
                  v-model="offer_select"
                  :placeholder="$t('seller.links.new.text_2726')"
                  label="name"
                  track-by="id"
                  :options="offers"
                  :multiple="false"
                  :taggable="false"
                  :class="{ heaven: isHeaven }"
                >
                  <template v-slot:noOptions>
                    <span>{{ $t("seller.links.new.text_2732") }}</span>
                  </template>
                  <template v-slot:noResult>
                    <span>{{ $t("seller.links.new.text_2732") }}</span>
                  </template>
                  </multiselect>
              </b-form-group>
            </div>
            <section class="add-bump">
              <h5 class="label-title" data-anima="top">Order bump</h5>
              <button class="btn-add" type="button" @click="() => addNewBump()">
                <span
                  class="label-title"
                  v-b-tooltip="`Adicionar novo produto como order bump`"
                  >+</span
                >
              </button>
            </section>
            <section v-for="(item, index) in bumps" :key="item.id">
              <b-row>
                <!-- Selects dos produtos order bumps -->
                <b-col
                  :cols="bumps.length !== 1 ? 11 : 12"
                  class="d-flex flex-column"
                >
                  <b-form-group label="Produto Order Bump">
                    <multiselect
                      data-anima="top"
                      v-model="item.bump"
                      :placeholder="$t('seller.links.new.text_286')"
                      label="name"
                      :name="name"
                      track-by="id"
                      :options="groupedProducts"
                      :multiple="false"
                      :taggable="false"
                      :class="{ heaven: isHeaven }"
                      @select="getBumpOffer"
                      @open="filterBy(seller_products, item.bump)"
                    >
                      <template v-slot:option="{ option }">
                        <span v-if="option.tag === 'Afiliado' && option.product_type_id === 3 && eventFlagTicket" class="affiliate-option">
                          {{ option.name }} 
                          - <span class="option-disable">{{ $t('seller.links.new.text_301') }}</span>
                          - <span class="option-disable">{{ $t('seller.links.new.text_302') }}</span>
                        </span>
                        <span v-else-if="option.product_type_id === 3 && eventFlagTicket">
                          {{ option.name }} - <span class="option-disable">{{ $t('seller.links.new.text_302') }}</span>
                        </span>
                        <span v-else-if="option.tag === 'Afiliado'" class="affiliate-option">
                          {{ option.name }} - <span class="option-disable">{{ $t('seller.links.new.text_301') }}</span>
                        </span>
                        <span v-else>{{ option.name }}</span>
                      </template>
                      <template v-slot:noResult>
                        <span>{{ $t("seller.links.new.text_2729") }}</span>
                      </template>
                    </multiselect>
                  </b-form-group>
                  <!-- Oferta do produto order bump -->
                  <template v-if="item.bump">
                    <b-form-group :label="$t('seller.links.new.text_2730')">
                      <multiselect
                        data-anima="top"
                        v-model="item.offer"
                        :placeholder="$t('seller.links.new.text_2731')"
                        label="name"
                        track-by="id"
                        :options="filterByOffer(item.bump.offers)"
                        :multiple="false"
                        :taggable="false"
                        :class="{ heaven: isHeaven }"
                      >
                        <template v-slot:noOptions>
                          <span>{{ $t("seller.links.new.text_2732") }}</span>
                        </template>
                        <template v-slot:noResult>
                          <span>{{ $t("seller.links.new.text_2732") }}</span>
                        </template>
                      </multiselect>
                    </b-form-group>
                    <div class="mark-down mb-3">
                      <p class="info-mark">
                        {{ $t("seller.links.new.text_2728") }}
                      </p>
                    </div>
                  </template>
                </b-col>
                <!-- Botão para excluir um order bump -->
                <b-col cols="1" v-if="bumps.length !== 1">
                  <button
                    type="button"
                    class="btn-remove"
                    @click="() => deleteBump(index)"
                  >
                    <span v-b-tooltip="`Remover order bump`">-</span>
                  </button>
                </b-col>
              </b-row>
            </section>
            <section v-if="cupons.length > 0">
              <h5 class="label-title" data-anima="top">
                {{ $t("seller.links.new.text_2736") }}
              </h5>
              <b-form-group :label="$t('seller.links.new.text_288')">
                <multiselect
                  data-anima="top"
                  v-model="cupom_select"
                  value=""
                  :placeholder="$t('seller.links.new.text_288')"
                  label="name"
                  track-by="id"
                  :options="cupons"
                  :multiple="false"
                  :taggable="false"
                  :class="{ heaven: isHeaven }"
                >
                  <template v-slot:noOptions>
                    <span>{{ $t("seller.links.new.text_2737") }}</span>
                  </template>
                  <template v-slot:noResult>
                    <span>{{ $t("seller.links.new.text_2737") }}</span>
                  </template>
                </multiselect>
              </b-form-group>
            </section>
          </div>
          <!-- Generated Link Update -->
          <div v-if="all_edit.is_bump === 1">
            <div data-anima="top">
              <b-form-group :label="$t('seller.links.new.text_2738')">
                <b-form-input
                  ref="linkconfig"
                  name="url"
                  :value="paymentLink"
                  type="text"
                  disabled
                  :class="{ heaven: isHeaven }"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
        </b-form>
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton
        variant="link-info"
        :disabled="loading"
        @click="cancel"
        class="mr-4"
      >
        {{ $t("seller.links.new.text_2740") }}
      </BaseButton>
      <BaseButton
        variant="link-info"
        class="mr-md-4"
        :disabled="loading"
        @click="copyLink"
      >
        {{ $t("seller.links.new.text_2741") }}
      </BaseButton>
      <BaseButton
        variant="primary"
        v-if="all_edit === null && (!$store.getters.setPanel || ($store.getters.setPanel && linkCreate))"
        :disabled="loading"
        @click="onSubmit"
      >
        {{ $t("seller.links.new.text_2742") }}
      </BaseButton>
      <BaseButton
        variant="primary"
        v-if="all_edit !== null && (!$store.getters.setPanel || ($store.getters.setPanel && linkEdit))"
        :disabled="loading"
        @click="onUpdate"
      >
        {{ $t("seller.links.new.text_2743") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { mapGetters } from "vuex";

import Multiselect from "vue-multiselect";

import LinkService from "@/services/resources/LinkService";
import CouponService from "@/services/resources/CouponService";
import ProductService from "@/services/resources/ProductService";
import ProductAllService from "@/services/resources/ProductAllService";
import CheckoutService from "@/services/resources/CheckoutService";
import gtagSend from '@/utils/gtagSend';



const service = LinkService.build();
const serviceCoupon = CouponService.build();
const serviceProduct = ProductService.build();
const serviceProductAll = ProductAllService.build();
const checkoutService = CheckoutService.build();

export default {
  name: "LinksNew",
  components: { Multiselect },
  props: {
    checkoutRead: {
      required: true,
      type: Boolean,
      default: false
    },
    linkCreate: {
      required: true,
      type: Boolean,
      default: false
    },
    linkEdit: {
      required: true,
      type: Boolean,
      default: false
    },
    linkDelete: {
      required: true,
      type: Boolean,
      default: false
    },
    product_installments: {
      type: Boolean,
    },
    checkouts: {
      type: Array,
    },
    _cupom: {},
    has_orderbump: {
      type: Boolean,
      default: false,
    },
    cupom_name: {
      type: String,
    },
    type: {
      type: Boolean,
    },
    productsS: {
      type: Array,
    },
    productsA: {
      type: Array,
    },
    product_id: {
      type: Number,
      default: -1,
    },
    product_type: {
      type: String,
    },
    product_name: {
      type: String,
      default: "",
    },
    product_format: {
      type: [String, Number],
      default: ""
    },
    defaultOfferIsFree: {
      type: Boolean,
      default: false
    },
    all_edit: {
      type: Object,
    },
    "is-physical": {
      type: Boolean,
    },
  },
 async mounted() {
    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      if (modalId === "links-new") {
        this.resetItens();
      }
    })

   await this.fetchAllProducts();
  },
  data() {
    return {
      isBump_Afiliate: false,
      url: "",
      name: "",
      source: "",
      medium: "",
      submit: false,
      loading: false,
      cupom: false,
      fixed_batchs: false,
      checkout: false,
      order_b: false,
      product_order_b: null,
      checkout_select: null,
      cupom_select: null,
      offer_select: null,
      batch_select: [],
      offer_bump_select: null,
      cupons: [],
      offers: [],
      offersBatches: [],
      offers_bump: [],
      seller_products: [],
      affiliate_products: [],
      // multiple order bumps
      bumps: [],
      bumpOptions: [],
      allProducts:{}
    };
  },
  computed: {
    ...mapGetters(["getFlags"]),
    groupedProducts(){
      // Agrupar os produtos em dois grupos: 'products' e 'affiliates'
      let formatArray = [];
      formatArray = this.allProducts.user_products.map(item => {
        return {
          id: item.id,
          name: item.name,
          price: item.price,
          description: item.description,
          image: item.image,
          type: item.type,
          tag: null,
          isAfilliate: false,
          product_type_id: item.product_type_id,
          $isDisabled: this.eventFlagTicket && item.product_type_id == 3 ? true : false
        }
      })
      formatArray = formatArray.concat(this.allProducts.affiliate_products.map(item => {
        return {
          id: item.product_id,
          name: item.name,
          price: item.price,
          description: item.description,
          image: item.image,
          type: item.type,
          isAfilliate: true,
          tag: 'Afiliado',
          product_type_id: item.product_type_id,
          $isDisabled: this.eventFlagTicket && item.product_type_id == 3 ? true : false
        }
      }))
      return formatArray;
    },
    eventFlagTicket() {
      if (this.getFlags.includes("feature_tickets")) return true;
      return false;
    },
    eventTickets() {
      if (this.getFlags.includes("feature_tickets") && this.product_format == 3) return true;
      return false;
    },
    showOrderBump(){
      if(this.eventTickets){
        return false;
      }
      return this.product_installments && !this.defaultOfferIsFree || (!this.offer_select || (this.offer_select || !this.selectedOfferIsFree));
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"];
    },
    /**
     * @returns {String} link - method returns payment link.
     */
    paymentLink() {
      /* Check if is heaven and get sales page from env and set link with product id */
      let link = this.isHeaven
        ? this.eventTickets
          ? `${process.env.VUE_SALES_HEAVEN_PAGE}/pre-checkout/${this.product_id}`
          : `${process.env.VUE_SALES_HEAVEN_PAGE}/${this.product_id}`
        : this.eventTickets
          ? `${process.env.VUE_SALES_FAST_PAGE}/pre-checkout/${this.product_id}`
          : `${process.env.VUE_SALES_FAST_PAGE}/${this.product_id}`

        this.isBump_Afiliate = this.bumps[0]?.bump ? this.bumps[0].bump.isAfilliate : false;

      /* Check if exists an offer selected and insert in payment link */
      if (!!this.offer_select) link = link + "/offer/" + this.offer_select.hash;        
      /* Variable to control queries  */
      let query = {};
      /* Check if exists coupon selected and set in query */
      if (!!this.cupom_select) query["cupom"] = this.cupom_select.name ?? this.cupom_name ;
      /* Check if exists checkout selected and set in query */
      if (!!this.checkout_select) query["ch_id"] = this.checkout_select.id;
      /* Check if exists affiliate id and set in query */
      if (!!this.type || this.isBump_Afiliate) query["a_id"] = this.userID();

      /* If exists bump order query, set in query */
      if (!!this.bumpsOrder) query = { ...query, ...this.bumpsOrder };
      /* If exists source and medium, set in query */
      if (this.source && this.medium) query[this.source] = this.medium;
      /* If exists batches order query, set in query */
      if (!!this.batchesOrder) query = { ...query, ...this.batchesOrder };
      
      link = link + "?" + new URLSearchParams(query).toString();
      return link;
    },
    /**
     * @returns {Object} joinded - method returns bump and offer formatted in pattern b_id_index and b_offer_index
     */
    bumpsOrder() {
      /* Check if has bump selected */
      if (!this.bumps[0]?.bump) return;
      let aux = [];
      /* iterate through bumps and define the aux with objects from the new pattern */
      this.bumps.forEach((item, index) => {
        if(item.offer){
          if(item.offer.method === 'FREE'){
              this.cupom = false
              this.cupom_select = null
              this.offer_bump_select = null
              this.cupons = []
              this.$bvToast.toast(this.$t("seller.links.new.text_300"), {
                  title: this.$t("seller.links.new.text_285"),
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
              });
              if(this.item) this.item.offer = null;
           }
        }
        aux.push({
          [`b_id_${index + 1}`]: !item.bump ? null : item.bump.id,
          [`b_offer_${index + 1}`]: !item.offer ? null : item.offer.hash,
        });
      });
      let joinded = {};
      aux.forEach((item) => {
        /* iterate through the aux array and puts everything in just one object */
        Object.assign(joinded, item);
      });
      /* iterate through the array and delete anything that is null */
      Object.keys(joinded).forEach((key) => {
        if (!joinded[key]) delete joinded[key];
      });
      return joinded;
    },
        /**
     * @returns {Object} joinded - method returns batches formatted in pattern bt_id_index
     */
     batchesOrder() {
      /* Check if has batches selected */
      if (!this.batch_select[0]?.id) return;
      let aux = [];
      /* iterate through batches and define the aux with objects from the new pattern */
      this.batch_select.forEach((item, index) => {
        aux.push({
          [`bt_id_${index + 1}`]: !item.id ? null : item.id,
        });
      });
      let joinded = {};
      aux.forEach((item) => {
        /* iterate through the aux array and puts everything in just one object */
        Object.assign(joinded, item);
      });
      /* iterate through the array and delete anything that is null */
      Object.keys(joinded).forEach((key) => {
        if (!joinded[key]) delete joinded[key];
      });
      return joinded;
    },
    selectedOfferIsFree() {
      return this.offer_select && this.offer_select.method === 'FREE'
    }
  },
  watch: {
    offer_select() {
      if(this.selectedOfferIsFree && this.order_b) {
        this.$bvToast.toast(this.$t("seller.links.new.text_2748"), {
          title: this.$t("seller.links.new.text_2745"),
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.cupom = false
        this.cupom_select = null
        this.cupons = []
        this.offer_select = null;
      }
    }
  },
  methods: {
    async fetchAllProducts() {
     await serviceProductAll
      .search()
      .then(response => {
          this.allProducts = response;      
        })
        .catch(error => {
          console.error('Erro ao buscar os produtos:', error);
        });
    },
    openModal() {
      if (this.all_edit !== null) {
        this.product_order_b = this.all_edit.product_order_b;
        this.offer_select = this.all_edit.offer_select
          ? this.all_edit.offer_select
          : null;
        this.offer_bump_select = this.all_edit.offer_bump_select
          ? this.all_edit.offer_bump_select
          : null;

        this.getBumps(this.all_edit);
        this.getOffers();

        if (this.offer_bump_select) {
          this.getOffers({ id: this.offer_bump_select.product_id });
        }
        
        if(this.all_edit.url.includes('ch_id')) {
          const url = this.all_edit.url;
          const regex = /ch_id=(\d+)/;
          const match = url.match(regex);
          if (match && match[1]) {
            this.checkout_select = { id: match[1] }
          }
        }
      }
      if (this._cupom && this.$parent.cupom) {
        this.cupom = true;
        this.changeCheckBox(true, "cupom");
        this.cupom_select = this._cupom;

        this.$bvToast.toast(this.$t("seller.links.new.text_295"), {
          title: this.$t("seller.links.new.text_2744"),
          variant: "info",
          autoHideDelay: 5000,
          appendToast: true,
        });
      } else {
        this.cupom = false;
        this.cupom_select = null;
      }

      if(this.eventTickets) {
        this.getOffers()
      }

      if (this.has_orderbump) {
        this.order_b = true;
      }

      this.cupons = [];
      this.offers = [];
      this.offers_bump = [];
      this.resetProducts();
      /* get seller products */
      this.seller_products = this.productsS;
      /* get affiliate products */
      this.affiliate_products = this.productsA;
      /* Init bumps lines */
      if (!this.all_edit) this.addNewBump(1);
    },
    async getOffersBatches(){

      let data = {
        id: this.product_id + "/offers/batches",
      };

      return await serviceProduct
        .read(data)
          .then((response) => {
            this.offersBatches = [];
            response.forEach((el) => {
              this.offersBatches.push({
                id: el.id,
                name: el.name,
              });
            });
          })
    },
    async getOffers(bump, payload = false, type = null) {
      this.loading = true;

      let data = {
        id: this.product_id + "/offers",
      };

      if (bump) {
        data.id = bump.id + "/offers";
      }

      return await serviceProduct
        .read(data)
        .then(async(response) => {
          /* check payload */
          if (payload) {
            /* set offers from response in bump.offers */
            payload.bump.offers = response;
            /* added new bump */
            this.bumps.push({
              ...payload,
              /* check if payload has offer, if payload.offer is null get offer from response */
              offer: payload.offer ? response.filter(item => item.hash === payload.offer).pop() : payload.offer
            })
            return response;
          }
          if (this.eventTickets && ((!bump && type != "orderbump") || (type && type != "orderbump"))) {
            await this.getOffersBatches();
          } else if(!bump){
            this.offers = [];
            response.forEach((el) => {
              this.offers.push({
                id: el.id,
                name: el.name,
                max_boleto_installments: el.max_boleto_installments,
                hash: el.hash,
                method: el.method
              });
            });
          } else {
            this.offers_bump = [];
            if (this.all_edit === null) {
              this.offer_bump_select = null;
            }
            response.forEach((el) => {
              this.offers_bump.push({
                id: el.id,
                name: el.name,
                max_boleto_installments: el.max_boleto_installments,
                hash: el.hash,
              });
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getCupom() {
      this.loading = true;

      let data = {
        id: `product/${this.product_id}`,
      };

      this.cupons = [];

      serviceCoupon
        .read(data)
        .then((response) => {
          response.forEach((el) => {
            this.cupons.push({
              id: el.id,
              name: el.name,
            });
          });
          if(this.cupom_select){
            this.cupom_select = this.cupons.find(x => x.name === this.cupom_name);
          }
        })
        .catch(err => console.error(err))
        .finally(() => {
          this.loading = false;
        });
    },
   async fetchCheckouts() {
      this.loading = true;
     await this.$parent.fetchCheckouts()
      .then((response) => {
        // this.checkouts = response;
      })
      .catch((err) =>  {
        console.log(err);
      })
      .finally(() => {
        this.loading = false;
      });
    },
    changeCheckBox(event, type) {
      if (event) {
        if (type == "cupom") {
          this.getCupom();
        }
        this.offer_select = null;
        this.getOffers(null, false, type);
        this.fetchCheckouts();
      } else {
        if (type == "cupom") {
          this.cupom_select = null;
        } else if (type == "checkout") {
          this.checkout_select = null;
        } else if (type == "orderbump") {
          this.product_order_b = null;
        } else if (type == "fixed_batchs") {
          this.offers.forEach(x => {
            x.tickets = 0
          });
        }
      }
    },
    filterBy(payload, selected = null) {
      const aux = Array.from(payload);
      this.bumpOptions = aux.filter((data) => {
        delete data.$isDisabled
        if (this.product_id == data.id) {
          return (data.$isDisabled = true);
        }
        if (data.product_type_id === 3 && this.eventTickets) {
          return (data.$isDisabled = true);
        }
        if (selected && selected.id === data.id) {
          return data
        }
        if (
          this.bumpsOrder &&
          Object.keys(this.bumpsOrder).filter(
            (key) => this.bumpsOrder[key] === data.id
          ).length
        ) {
          return (data.$isDisabled = true);
        }
        return data;
      });
    },
    filterByOffer(payload) {
      return payload.filter((data) => {
        if (
          data.max_boleto_installments !== 0 &&
          data.max_boleto_installments !== null
        ) {
          return (data.$isDisabled = true);
        } else {
          return data;
        }
      });
    },
    customLabel({ title }) {
      return `${title}`;
    },
    copyLink() {
      if (this.$refs.linkconfig) {
        navigator.clipboard.writeText(this.$refs.linkconfig.value);
        this.$bvToast.toast(this.$t("seller.links.new.text_296"), {
          title: this.$t("seller.links.new.text_2745"),
          variant: "success",
          autoHideDelay: 5000,
          appendToast: true,
        });
      }
    },
    resetItens() {
      this.order_b = false;
      this.cupom = false;
      this.checkout = false;
      this.product_order_b = [];
      this.checkout_select = null;
      this.cupom_select = null;
      this.$parent.cupom = null;
      this.medium = "";
      this.source = "";
      this.bumps = [];
      this.seller_products = [];
      this.affiliate_products = [];
      this.resetProducts();
    },
    userID() {
      return this.$store.getters.getProfile.id;
    },
    onUpdate() {
      let data = {
        id: this.all_edit.id,
        url: this.$refs.linkconfig.value,
        product_id: this.product_id
      };

      service
        .update(data)
        .then(() => {
          this.$emit("close");
          this.$bvModal.hide("links-new");
          this.$bvToast.toast(this.$t("seller.links.new.text_297"), {
            title: this.$t("seller.links.new.text_2745"),
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .finally(() => {
          this.submit = false;
          this.loading = false;
        });
    },
    onSubmit() {
      this.submit = true;
      this.loading = true;

      let urlMode = "";
      if (
        this.checkout === true ||
        this.order_b === true ||
        this.cupom === true ||
        this.fixed_batchs === true
      ) {
        urlMode = this.$refs.linkconfig.value;
      } else {
        if(!this.url){
          this.submit = false;
          this.loading = false;
        }else{
          try {
            let checkUrl = new URL(this.url)            
          } catch(err) {
            this.submit = false;
            this.loading = false;
          }

          let newQuery = this.source ? `${this.source}=${this.medium}` : "";
  
          urlMode = this.url;
          let has_query = new URL(urlMode);
  
          if (this.source) {
            urlMode += has_query.search ? `&${newQuery}` : `?${newQuery}`;
          }        
        }
      }

      this.$validator
        .validateAll()
        .then((result) => {
          if (result) {
            if (this.order_b && !this.paymentLink.includes('b_id')) {
              this.$bvToast.toast(this.$t("seller.links.new.text_2746"), {
                title: this.$t("seller.links.new.text_2745"),
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
              return;
            }

            if (this.checkout && this.checkout_select === null) {
              this.$bvToast.toast(this.$t("seller.links.new.text_2747"), {
                title: this.$t("seller.links.new.text_2745"),
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
              return;
            }

            let data = {
              url: urlMode,
              name: this.name,
              source: this.source,
              medium: this.medium,
              product_id: this.product_id,
            };

            if (this.order_b) {
              data.is_bump = true;
            }

            if (this.offer_select) {
              data.offer_select = this.offer_select;
            }

            if (this.offer_bump_select) {
              data.offer_bump_select = this.offer_bump_select;
            }

            service
              .create(data)
              .then((resp) => {
                gtagSend('link_adicionado', {
                  ID_Cliente: JSON.parse(localStorage.user).id || this.$store.getters.getProfile.id,
                  ID_produto: resp.product_id,
                  titulo_do_produto: this.product_name,
                  ID_Oferta: data?.offer_select?.id,
                  ID_OrderBump: this.order_b?.id,
                  ID_Checkout: this.checkout_select?.id,
                  ID_Cupom: this.cupom_select?.id,
                  titulo_do_link: resp.name,
                  ID_Link: resp.id,
                });
                this.$emit("close");
                this.url = "";
                this.name = "";
                this.source = "";
                this.medium = "";
                this.$bvModal.hide("links-new");
                this.$bvToast.toast(this.$t("seller.links.new.text_298"), {
                  title: this.$t("seller.links.new.text_2745"),
                  variant: "success",
                  autoHideDelay: 5000,
                  appendToast: true,
                });

                if (this._cupom) {
                  this.$parent.cupom = null;
                }
              })
              .finally(() => {
                this.submit = false;
                this.loading = false;
              });
          } else {
            this.submit = false;
            this.loading = false;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    /**
     * 
     * @param {Number} index - this is id to bump 
     */
    addNewBump(index = null) {
      this.restructuringBumps()
      let id = index ?? this.bumps.length + 1;
      this.bumps.push({
        id,
        bump: null,
        offer: null,
      });
    },
    /**
     * 
     * @param {Number} index - bump position in bumps array 
     */
    deleteBump(index) {
      this.bumps.splice(index, 1);
      this.restructuringBumps();
    },
    /**
     * @returns {void}
     * @description this method is called after add or exclude bump from array, he restructuring id of bumps.
     */
    restructuringBumps() {
      this.bumps = this.bumps.map((bump, index) => ({ ...bump, id: index + 1 }));
    },
    /**
     * 
     * @param {Object} product - receive bump as params, get offers by bump and set in product.offers 
     */
    getBumpOffer(product) {
      this.loading = true;
      let data = {
        id: product.id + "/offers",
      };
      serviceProduct
        .read(data)
        .then((response) => {
          product.offers = response;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    /**
     * @param {String} url - url of custom link.
     * @returns {void}
     * @description Get bumps of url and set url with new bump format, ex.: b_id => b_id_1
     */
    async getBumps({ url }) {
      /* Set url as URL method */
      url = new URL(url);
      /* Get search params from url */
      let search = url.search.substring(1);
      /* convert search params to object */
      let params = JSON.parse(
        '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
        function (key, value) {
          return key === "" ? value : decodeURIComponent(value);
        }
      );
      /* splitting order bumps and offers into arrays of separate arrays */
      let bumps = Object.entries(params).filter((item) =>
        item[0].includes("b_id")
      );
      let offers = Object.entries(params).filter((item) =>
        item[0].includes("b_offer")
      );
      /* joining arrays into objects */
      bumps = Object.fromEntries(bumps);
      offers = Object.fromEntries(offers);
      
      /* Checking if has offer and he is in new pattern */
      let oldOffer = null;
      const offerRegex = new RegExp('(b_offer_)(\\d*$)', 'i');
      if (offers) {
        Object.keys(offers).forEach(key => {
          if (key.includes('b_offer') && !key.match(offerRegex)) {
            oldOffer = offers[key]
          }
        })
      }
      /* going through the array of bumps to get the product, id and offer if any */
      Object.keys(bumps).forEach(async (key, index) => {
        let id = parseInt(key.slice(-1)) ? parseInt(key.slice(-1)) : index + 1;
        let product_seller = this.allProducts.user_products;
        let product_affiliate = this.allProducts.affiliate_products;
        
        let bump_s = product_seller
          .filter((item) =>{           
            return item.id === parseInt(params[key])})
          .pop();
        let bump_a = product_affiliate
          .filter((item) =>{        
            return item.product_id === parseInt(params[key])})
          .pop();
        if(bump_a){
          bump_a = {
            ...bump_a,
            id: bump_a.product_id
          }
        }

        let bump = bump_s ? bump_s : bump_a;

        await this.getOffers(bump, {
          id,
          bump,
          offer: offers[`b_offer_${id}`] ?? oldOffer
        }).then(() => {});
      });
    },
    resetProducts() {
      this.productsS.map(item => {
        if (item.$isDisabled) delete item.$isDisabled;
      })
      this.productsA.map(item => {
        if (item.$isDisabled) delete item.$isDisabled;
      })
    }
  },
};
</script>

<style scoped>
.option-disable{
  color: #81858e;
}
.produto-nome {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #2133d2;
}

.info-mark {
  margin: 0;
  font-weight: normal;
  color: #81858e;
  font-size: 13px;
  line-height: 1.5;
}

.label-title {
  font-size: 12px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #2133d2;
}
.add-bump {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
}

.add-bump .label-title {
  margin: 0;
}

.add-bump .label-title:last-child {
  font-size: 30px;
}

.btn-remove {
  font-size: 40px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  background: transparent;
  border: none;
}

.btn-remove span {
  color: #f00000;
}

.btn-add {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
}

.title-checkbox {
  font-size: 12px;
  line-height: 23px;
  letter-spacing: 0.2px;
  color: #333333;
  font-weight: normal;
}
.checks {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 20px;
}
.checks .title-checkbox {
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .checks {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
}
</style>
